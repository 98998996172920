.bedragen_rechts {
  .bedragen_rechts div {
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
      text-align: right;
    }
    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(6) {
      text-align: left;
    }
  }
}
