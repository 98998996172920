@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-ms-keyframes shrink {
  from {
    width: 200px;
  }
  to {
    width: 40px;
  }
}

@-moz-keyframes shrink {
  from {
    width: 200px;
  }
  to {
    width: 40px;
  }
}

@-webkit-keyframes shrink {
  from {
    width: 200px;
  }
  to {
    width: 40px;
  }
}

@keyframes shrink {
  from {
    width: 200px;
  }
  to {
    width: 40px;
  }
}

.loader,
.loader:after {
  width: 20px !important;
  height: 19px !important;
}

.loader {
  margin: 0 auto !important;
  border-radius: 100%;
  border-top: 2.1px solid var(--shadow-white) !important;
  border-right: 2.1px solid var(--shadow-white) !important;
  border-bottom: 2.1px solid var(--shadow-white) !important;
  border-left: 2.1px solid var(--white) !important;
  animation: spin 1.5s infinite linear;
}

.old_data {
  display: flex;
  width: 100%;
  border: 1px solid var(--warning-yellow-border);
  background-color: var(--warning-bg-yellow);
  padding: 6px 13px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-weight: 700;
  justify-content: space-between;
  cursor: pointer;

  .icon_container {
    display: flex;
    margin-top: 2px;

    &.active {
      animation: spin 1.5s infinite linear;
    }
  }

  &:hover {
    background-color: var(--warning-yellow-border);
  }

  &.invalid {
    border: var(--dark-grey);
    background-color: var(--mid-grey);
  }
}

.transition {
  min-width: 100px;
  transition: 0.25s all ease-out !important;

  &.active {
    min-width: 10px;
  }
}

.button_wrapper {
  display: inherit;
  justify-content: center;
  width: 100%;
}

.btn_loader {
  display: flex !important;
  justify-content: center;
}

.display_text {
  margin: 0px 15px;
}

.errorMessage {
  color: var(--validation-red-text);
}
