.button_wrapper {
  display: inherit;
  justify-content: center;
  width: 100%;
}

.button_container {
  display: flex;
  padding-top: 50px;
  padding-bottom: 30px;
}

.display_text {
  margin: 0px 15px;
}

.modal {
  &_footer {
    background: var(--light-grey);
    padding: 1.25rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &_body {
    flex-direction: column;
  }

  &_errors {
    flex-direction: row;
    text-align: left;
  }
}

.big_icon {
  font-size: 40px !important; // icon
  width: 70px; // img
}

.geenProducten {
  margin-top: -10px;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .text {
    text-align: center;
  }
}

.productKeuze {
  display: inline;
  margin-right: 0.5rem;
}