/*------------------------------------------------------------------------------------------------- 
    
    Sass File

-------------------------------------------------------------------------------------------------  */
// Vendors (3rd Party CSS)
@import "../node_modules/bootstrap/scss/bootstrap";

// Modules and Variables
@import "partials/_base.scss";

// Partials
@import "partials/_buttons.scss";
@import "partials/_cards-adviesbox.scss";
@import "partials/_dashboard.scss";
@import "partials/_drawer.scss";
@import "partials/_dropdown.scss";
@import "partials/_editable-table.scss";
@import "partials/_grids.scss";
@import "../../node_modules/adviesbox-shared/src/styles/partials/icons.scss";
@import "partials/_loader.scss";
@import "partials/_master-detail.scss";
@import "partials/_menu.scss";
@import "partials/_modal.scss";
@import "partials/_readonly-table.scss";
@import "partials/_search-table.scss";
@import "partials/_tooltips.scss";
@import "partials/_validation.scss";
@import "partials/_dropzone.scss";

[data-rbd-draggable-id] {
  will-change: transform, opacity;
}
