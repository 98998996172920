@import "../../../styles/modules/_variables.scss";

$parentcomponent-height: 30px;
$homepage-logo-height: 30px;
.homepage_logo {
  height: $homepage-logo-height;
  margin: 4px;
  margin-left: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.dropdown_menu {
  left: unset !important;
  right: 0 !important;
  transform: translateY(30px) !important;
}

.nav_icon {
  font-size: unset !important;
  align-self: flex-end;
}

.dropdown_item {
  background-color: var(--white);
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid var(--light-grey);
  .small_text {
    font-size: 12px !important;
    font-style: italic;
    line-height: 15px;
  }
}

.iconblack {
  &::before {
    color: var(--primary-brand-color) !important;
  }
}

.switch_environment_icon {
  padding: .5rem;
  font-size: 16px;
}

.switch_environment {
  color: var(--primary-brand-color) !important;
  cursor: pointer;
}

button.iconpadding {
  padding-top: 6px !important;
}

.nav_icon {
  font-size: unset !important;
  align-self: flex-end;

  &_new_entry_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 14px;
    right: 4px;
  }
}
