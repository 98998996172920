.product-overzicht {
    padding: 1rem;
    min-height: 41.75rem;

    &__list {
        padding: 0;
        margin: 0 0 1rem 0;
        list-style: none;
        position: relative;
    }

    &__item {
        list-style: none;
        border-left: 1px solid var(--mid-grey);
        margin-left: 1rem;

        &:last-child {
            border-left-color: transparent;
        }
    }

    &__text {
        position: relative;
        padding: 0.3rem 0 0 1.5rem;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -1px;
            bottom: 25%;
            width: 1rem;
            height: 1rem;
            border: 1px solid var(--mid-grey);
            border-top: none;
            border-right: none;
        }
    }
}