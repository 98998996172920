.mt_02 {
  margin-top: 2px;
}

.table_container {
  margin-top: 22px;
}

.no_results {
  padding-bottom: 57px;
  padding-top: 40px;

  &_title {
    color: #2e3131;
    font-size: 13px;
    font-weight: bold;
  }

  &_sub {
    font-size: 13px;
    color: #2e3131;
  }
}

.show_overflow {
  overflow: visible !important;
}
