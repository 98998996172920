.dashboard-container {
    &__content {
        background: #CAD5E3;
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        z-index: 2;
        padding-top: 4rem;

        &--open {
            transform: translateX(0);
        }

        &--closed {
            transform: translateX(100%);
        }
    }

    &__actions {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 1.25rem;
        padding-right: 1.5rem;
        z-index: 4;
    }

    &__header {
        margin: 1.4rem 1.25rem 0rem 1.4rem;
        padding: 1rem;
        font-size: 22px;
        font-weight: 400;
        background-color: var(--white);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    &__totalen {
        position: sticky;
        top: 65px;
        z-index: 2;
    }
}