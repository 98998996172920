.pdf {
  &_padding {
    margin-top: 30px !important;
  }

  &_header {
    color: #143f79;
    font-size: 30px;
    font-weight: bold;
    &_container {
      padding-top: 30px !important;
      display: flex !important;
      width: 100% !important;
      justify-content: space-around !important;
    }
  }

  &_page {
    left: 0px;
    top: 0px;
    max-width: 30.3cm !important;
    min-width: 30.3cm !important;
    min-height: 40.6cm !important;
    height: 40.6cm !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }

  &_hide {
    position: absolute !important;
    left: -10000px !important;
    top: 0px !important;
    overflow: hidden !important;
    transform: none !important;
    border: none !important;
    box-sizing: initial !important;
  }

  &_footer {
    display: flex;
    max-width: 850px;
    padding-bottom: 100px;
    flex-direction: column;

    &_header {
      padding-bottom: 30px;
    }

    &_sub {
      display: flex;
      flex-direction: column;

      &_rights {
        font-style: italic;
      }
    }
  }

  &_table {
    min-width: 900px;
    max-width: 900px;
    margin-top: 50px;
    margin-bottom: 50px;

    &_right_align {
      text-align: end;
      padding-right: 5px;
    }

    &_verzekering {
      &_header {
        &_left {
          font-weight: bold;
          color: white;
          text-align: right;
          padding-right: 10px;
          min-width: 300px;
          max-width: 300px;
          background-color: #143f79;
          border-right: 1px solid #2e5588;
        }
        &_right {
          background-color: #143f79;
          min-width: 600px;
          max-width: 600px;
        }
      }
    }
    &_header {
      color: white;
      background-color: #143f79;
      padding-left: 10px;

      &_center {
        border-left: 1px solid #2e5588;
        border-right: 1px solid #2e5588;
        padding-left: 10px;
        color: white;
        background-color: #143f79;
      }
    }
    &_dataTable {
      outline: 1px solid #768fb0;

      tr:nth-child(odd) {
        background-color: #f0f0f0;
      }
    }

    &_data {
      padding-left: 10px;
      max-width: 200px;
      min-width: 200px;
      &_info {
        border-right: 1px solid #446693;
        text-align: right;
        padding-right: 10px;
      }

      &_header {
        color: white;
        padding-left: 5px;
        border-right: 1px solid #2e5588;
        background-color: #143f79;

        &_first {
          max-width: 170px;
          min-width: 170px;
        }

        &_second {
          max-width: 230px;
          min-width: 230px;
        }

        &_rest {
          max-width: 100px;
          min-width: 100px;
        }

        &_align_right {
          max-width: 100px;
          min-width: 100px;
          text-align: right;
          padding-right: 5px;
        }
      }
    }
  }
}
