.emptyGrid {
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  .header {
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .text {
    text-align: center;
  }
}

.pdf {
  padding: 5px;
  padding-right: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &_txt {
    margin: 0;
  }
}
