.input_control {
  display: block;
  height: calc(1.25rem + 5px);
  &.is_textarea {
    height: initial;
  }
  padding: 0.2rem 0.4rem;
  font-family: var(--source-sans-pro);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--border-color-normal);
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: var(--text-color);
    background-color: var(--white);
    border-color: var(--border-color-focus);
    outline: 0;
    box-shadow: 0 0 0 2px rgba(46, 49, 49, 0.25);
  }
  &:disabled,
  &:read-only {
    border: none;
    color: var(--disabled-text-color);
  }
}

.input_control::placeholder {
  color: var(--placeholder-text);
  opacity: 1; /* Firefox */
  text-align: left !important;
}

.input_control:-ms-input-placeholder,
.input_control::-ms-input-placeholder {
  /* Internet Explorer 10-11 and Edge */
  color: var(--placeholder-text);
  text-align: left !important;
}

.input_control:placeholder-shown {
  direction: ltr !important;
  text-align: left !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 28px;
}

.dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  right: -372px;
  width: 400px;
  background-color: white;
  position: absolute;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown li {
  padding: 5px 10px;
  border-bottom: 1px solid #e5e5e5;
}

.bevestiging i {
  color: green;
}

.bevestiging u {
  cursor: pointer;
}

.geen_standaardteksten {
  &:hover {
    background-color: #fff !important;
    cursor: auto !important;
  }
}

.dropdown li:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}

.button {
  padding: 3px;
  margin-right: 5px;
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
}

.dropdownbutton {
  background: none;
  border: none;
}

.button:hover {
  background-color: var(--light-grey);
  cursor: pointer;
}
