.werkloosheid-drawer {
  margin: -0.5rem 0rem 2rem 0rem;
  padding: 1rem;

  .card {
    min-width: 0 !important;
  }
}

.snel-inzicht {
  &__loading {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  &__column {
    transition: flex-basis 0.3s ease, min-width 0.3s ease-in, max-width 0.3s ease-out;
    overflow: hidden; /* Prevent layout changes during transition */
  }

  &__button {
    padding: .29rem !important;
  }
}

.scenario {
  &__header {
    margin: 0.3rem 0.3rem 1.5rem;
    padding: 1rem;
    font-size: 22px;
    font-weight: 400;
    border-radius: 0.25rem;
    background-color: var(--white);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  &__card {
    .card-body {
      min-height: 45rem;
    }
  }
}

.scenario-table {
  th,
  td {
    padding: 0.3em !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}