/*-----------------------------------------
            Font Family 
------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,700&display=swap');
@import url("https://fonts.googleapis.com/css?Source+Sans+Pro:400,700");

:root {
  --text-font: "Source Sans Pro", sans-serif;
  --header-font: "Roboto", sans-serif;
}
