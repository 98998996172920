.acceptatie-chart {
    width: 100%;

    @media only screen and (min-width: 2300px) {
        width: 64rem;
    }

    &__chart {
        height: 20rem;
        padding: 1rem;
    }
}