/*-------------------------------------
        waardeopbouw-table
-------------------------------------*/

.waardeopbouw-table {
  min-width: calc(100% + 2.5rem);
  margin: 0 -1.25rem;
  background-color: var(--white);
  cursor: pointer;
  border: none;

  .rt-tbody {
    .rt-tr-group {
      &:last-child {
        &.selected {
          border-bottom: 1px solid var(--primary-border) !important;
        }
      }
    }
  }

  .rt-resizer {
    display: none;
  }

  .rt-table {
    padding: 1rem;

    .rt-thead {
      &.-header {
        text-align: left;
        font-size: 11px;
        font-weight: bold;
        line-height: 14px;
        color: var(--darkest-grey);
        box-shadow: none;
        cursor: default;
      }

      .rt-th {
        border-right: none;
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }

    .rt-tbody {
      color: var(--text-color);
      line-height: 26px;
      font-size: 13px;
      border-left: none;
      border-right: none;
      border-top: 1px solid var(--border-color-normal);
      border-bottom: 1px solid var(--border-color-normal);
      overflow: hidden;

      .rt-tr-group {
        border-bottom: 1px solid var(--border-color-normal);

        &:last-child {
          border-bottom: none;
        }
      }

      .rt-td {
        height: 25px;
        padding: 0 5px;
        border-right: none;

        display: flex;
        justify-content: flex-end;

        &:first-child {
          padding-left: 10px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}
