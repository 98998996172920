.close_btn {
  i {
    font-size: 10px;
  }
}

.p_top {
  padding-top: 20px;
}

.modal_footer {
  background: var(--light-grey);
  padding: 10px 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
