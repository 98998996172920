.dropdown{

  &__popup {
    width: 480px;
    min-height: 320px;
  }

  &__title {
    min-height: 20px;
    padding: 10px 14px;
    span:first-of-type {
      font-weight: bolder;
    }
    span {
      font-size: small !important;
      margin: 0;
      margin-right: 5px;
    }
  }

  &__body {
    height: 303px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: 0;
  }
}

.no-data {
  text-align: center;
  padding: 20px;
  color: #666;
}

.delayed-show {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
  animation-delay: 2.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 