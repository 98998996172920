.left {
  display: inline-block;
  width: 50%;
}

.right {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.padding_left {
  padding-left: 5px;
}

.datagrid {
  max-width: 450px;
}
