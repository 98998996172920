.bordered_result {
  border-left: 1px solid var(--darkest-grey);
  border-right: 1px solid var(--darkest-grey);
}

.platform_errors {
  word-wrap: break-word;
  min-width: 400px !important;
  max-width: 450px !important;
  padding: 2px !important;
}
