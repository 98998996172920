.financieringskosten {
  margin-left: .85rem;
  margin-top: .3rem;
}

.custom_percentage_input {
  > input {
    max-width: 50px !important;
    min-width: 50px !important;
  }

  > div {
    padding: 0 !important;
    > div {
      text-align: left;
      width: calc(var(--small) + 5px) !important;
    }
  }
}