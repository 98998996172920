/*------------------------------------------------------------------------------------------------- 
    Navigation
------------------------------------------------------------------------------------------------- */

/* Top navigation */

.fixed-top {
  position: sticky;
  height: 3.75rem;
  padding-right: 0 !important;

  @include breakpoint($medium-screens) {
    position: relative;
  }
}

.navbar-light {
  background: var(--white);
}

.navbar {
  border-radius: 0;
  border: 0 solid transparent;
  margin-bottom: 0;
  padding: 0;

  &-nav {
    margin-left: auto;

    .nav-item {
      @include flex();
      align-items: center;

      .nav-link {
        @include font-title(var(--header-font), 700, 13px, var(--darkest-grey));
        padding: .375rem .625rem;

        &:hover,
        &:focus,
        &:active,
        &:active:hover,
        &:active:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
          border: none;
          border-radius: 0;
        }

        .icon {
          font-size: 12px;
        }
      }
    }

    .dropdown {
      &-toggle {
        &:after {
          display: none;
        }
      }
    }

    .apps-container {
      top: -.188rem !important;
      left: -1.563rem !important;

      .card-container {
        @include flex();
        @include flex-direction(row);

        &:not(:last-of-type) {
          .dropdown-item {
            border-bottom: 1px solid var(--dropdown-bottom-border);
          }
        }
        .dropdown-item {
          &:not(:last-of-type) {
            border-right: 1px solid var(--dropdown-bottom-border);
          }
        }
      }

      .card-small {
        width: 6rem;
        border: none;
        border-bottom: none !important;
        margin: 0 auto;

        .caption {
          padding-top: .313rem;
          p {
            text-align: center;
          }
        }

        figure {
          @include flex();
          align-items: center;
          justify-content: center;
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 50%;
          margin: 0 auto;

          h4 {
            font-size: var(--font-brand);
            font-weight: 400;
            color: var(--white);
            margin: 0;
          }
        }
      }
    }

    .adviseur {
      &-container {
        @include flex();
        align-items: center;

        .dropdown-menu {
          top: .188rem !important;
          left: .188rem !important;
        }
      }

      &-naam {
        width: 11.25rem;
        vertical-align: middle;
        font-weight: 700;
        @include ellipsis();
        @include flex();
        align-items: center;

        .icon {
          margin-left: .625rem;
          font-size: 4px !important;
        }
      }
    }
  }
}

.second-navbar {
  background-color: var(--light-grey);
  border-bottom: 1px solid var(--mid-grey);

  .main-nav {
    @include flex();
    flex-wrap: wrap;
    align-items: center;
    flex: 0 0 100%;
  }

  .navbar-nav {
    margin-left: 0;

    .nav-link {
      padding: 0.33rem 1rem;
      color: var(--black);
    }
  }

  .aanvrager-container {
    position: relative;
    margin: 0 auto;
  }
}

/*-----------------------------------
    Collapsed menu (only icons view)
------------------------------------*/
.collapsed {
  .sidebar-nav-container {
    width: 3.75rem;
    overflow: hidden;

    .navbar-brand {
      .brand {
        .brand-name {
          visibility: hidden;
        }
      }
    }

    .nav-side-menu {
      .menu-list {
        .menu-minimal {
          visibility: hidden;
        }
        .menu-content {
          &-expanded {
            background-color: var(--primary-brand-color);
          }
          .menu-item {
            width: 3.75rem;

            &-text {
              visibility: hidden;
            }
          }

          .sub-menu {
            display: none !important;
            overflow: hidden;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
          }
        }
      }
    }
  }
}

.menu-item {
  .has-submenu {
    .menu-item-text {
      &::before {
        content: '\02C3';
        left: 0;
        font-weight: bold;
        font-family: monospace;
        position: absolute;
        margin-left: 1rem;
        transform: rotate(0deg);
        transition: transform 0.3s ease;
      }
    }

    &[id$="-open"] .menu-item-text::before {
      transform: rotate(90deg);
    }
  }
}


/*---------------------------------------------
      Side Navigation Menu 
-----------------------------------------------*/

.sidebar-nav-container {
  position: fixed;
  width: 15.625rem;
  top: 0;
  bottom: 0;
  left: 0;
  color: white;
  background: var(--sidemenu-bg-color);
  z-index: 1000;
  @include transition(all 0.3s ease);

  .nav-side-menu {
    height: 100%;
    @include flex();
    @include flex-direction(column);
    flex: 1 0 auto;

    .navbar-brand {
      display: flex;
      font-size: var(--font-brand);
      font-weight: 700;
      padding: 3rem 1.5rem 3rem 1.5rem;
      margin-right: 0;
      background-color: var(--primary-brand-color);

      &-collapsed {
        padding: 3rem 0rem 3rem .75rem;
      }

      .brand {
        @include flex();
        @include flex-direction(row);
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        padding: 0 0.375rem;

        &-name {
          padding-left: .438rem;
          &:after {
            padding-left: .188px;
          }
        }
      }
    }

    .menu {
      &-label {
        margin-bottom: .5rem;
      }
      &-list {
        .scrollbar {
          height: calc(100vh - 12.5rem);
        }
        .menu-minimize {
          @include flex();
          align-items: center;
          position: absolute;
          bottom: 0;
          flex-grow: 1;
          -webkit-box-flex: 1;
          align-items: stretch;
          width: 100%;
          padding: 1rem 1rem 0rem 1rem;
          border-top: 1px solid var(--sidemenu-border-bottom-minimize);
          cursor: pointer;
          background: var(--sidemenu-bg-color);
          height: 3.75rem;

          &-text {
            @include flex();
            @include flex-direction(row);
            -webkit-align-items: center;
            align-items: center;
            -webkit-flex-grow: 1;
            flex-grow: 1;
            margin: -.313rem .5rem;
            font-size: .75rem;
            font-style: italic;
            font-weight: normal;
            width: 12.5rem;
            opacity: 0.5;
            @include ellipsis();
          }

          .icon {
            font-size: .625rem !important;
            padding-bottom: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &-content {
        margin: 0;
        padding: 0;
        list-style: none;

        &-main {
          border-bottom: 1px solid #3D4042;
        }

        &-expanded {
          background-color:  var(--primary-brand-color);
        }

        &.open {
          > .menu-item > .sub-menu {
            max-height: 100vh;
          }
        }

        .menu-item {
          @include transition(all 0.3s ease);

          &-link {
            @include transition(all 0.3s ease);
            @include flex();
            flex-grow: 1;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
            position: relative;
            margin: 0;
            padding: 1rem 1.5rem 1rem 1.5rem;
            font-size: var(--font-p);
            text-decoration: none;
            cursor: pointer;

            &:hover {
              color: var(--primary-gold);
            }

            &.active {
              font-weight: 700;
              background-color: var(--primary-gold);
              color: var(--black);
              border: none;

              &:hover {
                color: var(--black);
              }
            }

            &.show-wait {
              cursor: wait;
            }
          }
          &-error {
            color: red;
            font-weight: bold;
            font-size: 10px;
          }

          &-image {
            @include flex();
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            margin-left: 1rem;

            .icon {
              font-size: var(--font-p);
            }
          }

          &-text {
            @include flex();
            @include flex-direction(row);
            font-size: 18px;
            align-items: center;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            width: 100%;
            display: block;
            flex-grow: 1;
            padding: 0;
            text-transform: initial;
            @include ellipsis();
          }

          &-arrow {
            @include flex();
            align-items: center;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            line-height: 0;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 1.25rem;
            flex: 0 0 1.25rem;

            .icon {
              font-size: 10px !important;
            }
          }

          .sub-menu {
            @include flex();
            @include flex-direction(column);
            -webkit-box-direction: normal;
            -webkit-box-orient: vertical;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            padding: 0;
            margin: 0;
            list-style: none !important;
            background-color:  var(--primary-brand-color);
            overflow: hidden;
            max-height: 0;
            @include transition(all 0.3s linear);

            .menu-content {
              &-expanded {
                background-color: var(--primary-brand-color);
                
              }
              &:first-child {
                margin-top: -.25rem;
              }
              &:last-child {
                padding-bottom: 1rem;
              }
              .menu-item {
                &-link {
                  padding: 0rem;
                }
                &-text {
                  margin-left: 2.5rem;
                  font-size: 16px;
                  padding: .75rem 0rem .4rem 0rem;
                  border-bottom: 1px solid #FFFFFF26;
                  &-last {
                    border-bottom: none;
                  }

                  &-image {
                    margin-left: 0rem !important;
                  }
                }
                .sub-menu {
                  .menu-content {
                    .menu-item {
                      &-link {
                        :hover {
                          color: var(--primary-gold);
                        }
                        padding: .1rem 0rem .1rem 1rem;
                        border-bottom: none;

                        &.active,
                        &:active {
                          :hover {
                            color: var(--black);
                          }
                          font-weight: 700;
                          background-color: var(--primary-gold);
                          color: var(--black);
                          border: none;
                        }
                      }

                      &-image {
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        padding-left: .75rem;
                        .icon {
                          font-size: var(--font-p);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}