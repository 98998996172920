.dashboard.collapsed p.tekort {
  display: none !important;
}

.btn_fetch_small span {
  font-size: 0.7rem !important;
  margin: 3px !important;
}

.chevron_up {
  transform: rotate(-180deg);
  position: relative;
  top: 1px;
  display: inline-block;

  i {
    font-size: 6px;
    font-weight: bolder;
    padding: 0 0.5rem 0 0.5rem;
  }
}

.chevron {
  display: inline-block;
  i {
    font-size: 6px;
    font-weight: bolder;
    padding: 0 0.5rem 0 0.5rem;
  }
}

.btn_striped {
  .btn {
    z-index: 10;
  }
  &::before {
    content: " ";
    width: 100%;
    position: absolute;
    border-bottom: solid 1px rgba(204, 204, 204, 1);
    display: block;
    transform: translate(-10px, 18px);
  }
}

:not(.dashboard.collapsed) .head_text {
  padding: 0;
  margin: 0;

  &::before {
    content: none;
  }
}

.calculation_line {
  width: 215px !important;

  &_with_minus {
    display: inline-table !important;
  }
}

.api_error {
  max-width: 150px;
}
