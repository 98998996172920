.img {
  padding: 10px;
  max-height: 350px;
}

.bold_margin {
  font-weight: bold;
  margin-top: 5px;
}

.italic_margin {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 25px;
}
