.modal_footer {
    background: var(--light-grey);
    padding: 10px 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  
  .button_container {
    i {
      font-size: 10px;
    }
  }
  
  .delete {
    display: flex;
    margin-top: 1px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  