.rapportage_structuur_wrapper {
  & > .bordered:first-child {
    border-top: 1px solid var(--darkest-grey);
  }
}

.bordered {
  border: 1px solid var(--darkest-grey);
  border-top: none;
  border-right: none;
}

.structuur_table {
  width: 100%;

  border-top: 1px solid var(--darkest-grey);
  border-right: 1px solid var(--darkest-grey);

  .drag_n_drop_wrapper {
    color: #dedede;
    & > i {
      padding-left: 2px;
      transform: translateY(2px);
      display: inline-block;
    }
  }

  .drag_n_drop_wrapper,
  .checkbox_wrapper,
  .label_wrapper,
  .expand_wrapper,
  .spacer {
    display: inline-block;
    height: 100%;
    padding: 0.125rem;
  }
  .expand_btn {
    padding: 0;

    width: 20px;
  }

  .expand_wrapper {
    width: 1.2rem;
    text-align: center;
  }

  .drag_n_drop_wrapper {
    width: 2rem;
  }
  .checkbox_wrapper {
    width: 2rem;
    padding: 0 0.5rem;
    text-align: center;

    & > input {
      transform: translateY(0.125rem);
    }
  }
  .label_wrapper::first-letter {
    text-transform: capitalize;
  }

  .spacer {
    content: "";
    width: 1.5rem;
  }

  & tbody tr {
    display: inline-block;
    width: 100%;

    & td {
      display: inline-block;
      padding: 0;
      width: 100%;
    }
  }
}

.main_cell {
  border: 1px solid var(--darkest-grey);
  border-top: none;
  border-right: none;
  display: inline-block;
  width: 100%;
  position: relative;
}

.edit_cell {
  position: relative;
  width: 35px !important;
  display: block;
  position: absolute;
  height: 24px;
  right: 10px;
  transform: translateY(-25px);
  top: -1px;

  & > button {
    padding: 2px 5px;
  }
}

.edit_cellcontainer {
  position: relative;
  z-index: 1000;
}
