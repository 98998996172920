.cellGroup {
  background-color: #fff;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .highlighted {
    background-color: rgba(215, 215, 215, 1);
  }
}

.cellGroup div {
  display: block;
  color: #000;
  border: 1px solid #000;
  text-align: center;
  padding: 15px 0px;
  height: 50px;
  width: 100%;
}

.cellGroup div:not(:last-child) {
  border-right: none;
}
