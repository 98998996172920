.drawer {
    &__body {
        position: relative;
        overflow-x: clip;
    }

    &__inner-body {
        transition: opacity .25s ease-in-out;
    }
}
