
.loading {
    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.6); /* semi-transparent background */
      z-index: 1000;
    }
  
    &__container {
      margin-top:-200px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 5;
      background-color: white;
      padding: 2rem;
    }
  
    &__spinner {
      width: 13rem;
      height: 13rem;
      border-width: 30px;
      color: var(--primary-gold); /* Custom color (you can change this) */
      border-width: 0.5em; /* Ensures the custom color spinner is also thick */
    }
  
    &__text {
      margin-top: 1rem;
    }
  }