/*------------------------------
            Tool-tips 
--------------------------------*/
.info-tip,
.question-tip,
.warning-tip {
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  margin-left: 5px;
  margin-top: -3px;

  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;

  &.btn {
    padding: 0;
    &-primary {
      &:hover {
        color: none;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  &:before {
    width: 16px;
    height: 16px;
    background: var(--light-grey);
    border-radius: 5px;
    @include font-title(var(--header-font), 700, 12px, var(--darkest-grey));
    text-align: center;
    line-height: 16px;
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: none;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    outline: 0;
    &:before {
      background: var(--dark-grey);
      color: var(--white);
    }
  }
}

.warning-tip {
  &:before {
    width: 16px;
    height: 16px;
    background: var(--dark-grey);
    border-radius: 5px;
    @include font-title(var(--header-font), 700, 12px, var(--darkest-grey));
    text-align: center;
    line-height: 16px;
    cursor: pointer;
    color: var(--red);
  }

  &:hover,
  &:focus,
  &:active,
  &:active:hover,
  &:active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: none;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    outline: 0;
    &:before {
      background: var(--dark-grey);
      color: var(--red);
    }
  }
}

.warning-tip {
  &:before {
    content: "!";
  }
}

.info-tip {
  &:before {
    content: "i";
  }
}

.question-tip {
  &:before {
    content: "?";
  }
}

.tooltip {
  .arrow {
    &:before {
      border-right-color:var(--text-color);
      opacity: 0.9;
    }
  }

  &-inner {
    max-width: 400px;
    background-color: var(--text-color);
    border: 1px solid var(--text-color);
    border-radius: 5px;
    @include font-title(var(--text-font), 400, 13px, var(--white));
    text-align: left;
    line-height: 17px;
    white-space: pre-wrap;
  }

  &_correction {
    top: 56px !important;
  }
}
