.snel-inzicht {
    margin: -.5rem 0rem 2rem 0rem;
    padding: 1.5rem;

    .card {
        min-width: 0 !important; 
    }

    &__no-padding {
        padding-left: 0 !important;
    }

    &__overzicht-button {
        padding: .48rem .29rem .48rem .29rem !important;
    }
}

.maandlastenoverzicht {
    width: 75%;
    height: 100%;
    &__chart {
        width: 100%;
    }
}