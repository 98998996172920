.tekort {
    width: 100%;
    &__container {
        border: none !important;
        width: 100%;
        font-weight: 100;
    }

    &__item {
        border: none !important;
        font-weight: 100;
    }

    &__text {
        padding-left: 1rem !important;
    }

    &__icon {
        padding-left: .25rem;

        &--aanvrager,
        &--aanvrager_2 {
            &::after {
                position: absolute;
                margin-left: .1rem;
                font-size: 11px;
                font-weight: 700;
                font-family: sans-serif;
            }
        }
    
        &--aanvrager::after {
            content: "1";
        }
    
        &--aanvrager_2::after {
            content: "2";
        }
    }
}