.accent-column {
    background-color: aliceblue;
}

// bold-totals
.strong_last tr:last-child {
    font-weight: bold;
}

.lastenoverzicht-table {
    min-height: 300px;
}

.width-80 {
    width: 80px;
}
