.form-group.form-row {
  color: red !important;
  padding: 30px;
}

.percentage_offset > div > div {
  width: var(--large);
  padding-right: 0.5rem;
  & > div {
    width: var(--medium);
    text-align: right;
    display: inline;
    padding-right: 0.4rem;
  }
}
