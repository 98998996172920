@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.busy {
    margin-top: 4px;
    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: -19px;
      border: 2px solid var(--mid-grey);
      border-top: 2px solid var(--darker-grey);
      border-radius: 50%;
      animation: spin 2s linear infinite;
      height:16px;
      width:16px;
    }
  }