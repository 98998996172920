.padding_b_10 {
  padding-bottom: 10px;
}
.margin_b_5 {
  margin-bottom: 0.5rem !important;
}

.wens_width {
  display: inline-block !important;
  width: 100%;
}
