.dropdown {
  .btn-link {
    border: none !important;
    border-radius: 0 !important;
  }

  &-menu {
    @include font-title(var(--text-font), 400, 13px, var(--text-color));
    background: var(--white);
    border-radius: 0.14rem;
    padding: 0;
    border: 1px solid var(--mid-grey);
    box-shadow: 0 2px 15px 0 var(--shadow-black-01);

    .dropdown-item {
      font-weight: normal;
      font-size: var(--font-th);
      background-color: var(--white);
      padding: 0.5rem 1.5rem;
      word-break: break-all;
      cursor: pointer;

      .small-text {
        font-size: 12px !important;
        font-style: italic;
        line-height: 15px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--light-grey);
      }

      &:hover,
      &:focus,
      &:active {
        color: var(--text-color);
        background-color: var(--light-grey);
      }
    }
  }

  &-toggle {
    &:after {
      vertical-align: middle;
      border-top: none;
    }
  }
}
