.table_overige_inkomsten {
  margin: 0 0.25rem;

  tr {
    th {
      color: var(--dark-grey);
      font-size: 12px;
    }
  }
}

.table_input {
  padding-left: .75rem;
  margin-bottom: .25rem;
  padding-top: .2rem;
}