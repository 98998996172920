.hypotheek-totalen {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 1rem 1rem .25rem;
    background-color: #EFEFEF;
    border: 1px solid rgba(0, 0, 0, 0.125);
    z-index: 2;
    position: relative;

    &__sticky {
        position: sticky;
        z-index: 2;
        top: 65px;
    }

    &__entry {
        margin: 0rem .25rem 0rem 1rem;
        padding-right: 1rem;
        display: flex;
        border-right: 1px solid rgba(0, 0, 0, 0.125);
    }

    &__label {
        line-height: 40px;
        font-size: var(--font-p);
        color: var(--gray);
        margin-bottom: 0;
    }

    &__price {
        margin-left: .5rem;
        color: var(--black);
        font-size: var(--font-p);
    }

    &__percentage {
        color: var(--black);
        font-size: var(--font-p);
    }

    &__border {
        font-size: var(--font-p);
    }

}