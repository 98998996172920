$balk-hoogte: 30px;

.icon_container {
  position: relative;
  top: -1px;
  margin-left: 5px;

  i {
    font-size: 6px;
  }
}

.rapportage_dropdown {
  border-left: 1px solid var(--mid-grey);
}

.rapportage_dropdown_menu {
  min-width: max-content !important;
  margin: 0 !important;
}

.fouten_dropdown_menu {
  min-width: max-content !important;
  margin: 0 !important;
  transform: translate(0px, $balk-hoogte) !important;
}

.aantal_fouten {
  background-color: rgba(214, 25, 25, 1) !important;
  margin-right: 0.2em;
  border-radius: 50%;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 18px;
  height: 18x;
}

.platform_errors {
  word-wrap: break-word;
  min-width: 400px !important;
  max-width: 450px !important;
  padding: 2px !important;
}

.loader {
  z-index: 999 !important;
  position: fixed !important;
  left: 45%;
}