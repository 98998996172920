.new-dashboard {
    height: 100%;
    margin-top: 4.25rem;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    font-size: .9rem;
    line-height: 1.6rem;
    width: 100%;
    z-index: 3;
    background-color: #EFEFEF;

    &__content {
        background-color: #EFEFEF;
        height: calc(100% - 8rem);
        padding-top: 1.25rem;
        position: fixed;
        width: -webkit-fill-available;
        overflow-y: auto;
    }

    &__title {
        text-align: center;
    }

    &__button {
        width: 80%;
        margin-bottom: 1rem;
    }

    &__entry {
        &--selected, &:hover {
            background-color: #e0e0e0;
        }

        padding: 0 1.25rem 0 1.25rem;
    }

    &__entry-btn {
        border: none;
        text-align: left;
        margin: 0;
        padding: .5rem .5rem .5rem .5rem;
        margin-bottom: .5rem;
        display: block;
        background: transparent;
        border-bottom: 1px solid var(--border-color-normal);
        width: 100%;

        h2 {
            margin-top: .5rem;
        }
    }
}