.button_wrapper {
  display: inherit;
  justify-content: center;
  width: 100%;
}

.button_container {
  display: flex;
  padding-top: 50px;
  padding-bottom: 30px;
}

.display_text {
  margin: 0px 15px;
}

.modal {
  &_footer {
    background: var(--light-grey);
    padding: 1.25rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &_body {
    flex-direction: column;
  }

  &_errors {
    flex-direction: row;
    text-align: left;
  }
}
