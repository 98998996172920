.margin_correction {
  margin-top: -5px;
  height: 100%;

  input {
    margin-top: 5px;
    z-index: initial !important;
    height: 15px;
  }
}
