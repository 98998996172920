@import "../../../styles/modules/variables.scss";

.category-heading {
    background: #FFCC00;
    display:block;
}

.limiter {
    max-height: 800px !important;
    overflow-x: scroll;
}