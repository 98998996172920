.modal_footer {
  background: var(--light-grey);
  padding: 10px 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.button_container {
  i {
    font-size: 10px;
  }
}