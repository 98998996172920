@import "../../styles/modules/_variables.scss";

.button {
  height: 10px;
  width: 10px;
  background: transparent;
  border: white 1px solid;
  border-radius: 2px;
  margin: 0;
  position: relative;

  .button_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -45%);
    font-size: 8px;
    -webkit-text-stroke: 1px;
    display: none;

    &:before {
      color: var(--primary-brand-color);
    }
  }

  &_hidden {
    display: none;

    &:checked + .button_wrapper {
      background: var(--primary-brand-color);

      > .button {
        background: white;
        .button_icon {
          display: initial;
        }
      }
    }
  }

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 16px;
    margin-left: 7px;
    border: 1px solid transparent;
    margin-bottom: 0;
    border-radius: 4px;
    font-weight: 600;
    font-size: var(--font-btn);
    color: var(--white);
    background: var(--primary-brand-color);
  }

  &_text {
    margin-left: 5px;
  }
}
