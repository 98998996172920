.dossier_fouten {
  width: 350px;
  .dropdown_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 2px 0px 10px;
    span:first-of-type {
      font-weight: bolder;
    }
    span {
      font-size: small !important;
      margin: 0;
      margin-right: 5px;
    }
  }
  .dropdown_body {
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: 0;
  }

  .entry {
    position: relative;
    padding: 10px;
    min-width: 210px;
    margin: 5px;
    border: 1px solid #d5d5d6;
    border-radius: 5px;
    word-wrap: break-word;
    &_title {
      display: flex;
      margin-right: 1.5px;
      &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
    &_body {
      word-wrap: break-word;
      &_link {
        padding: 0 2.5px;
      }
      &_i {
        font-style: italic;
      }
    }
    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: var(--light-grey);
    }
  }

  // icon
  i {
    cursor: pointer;
    font-size: medium;
    padding: 0;
  }
  .link {
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    padding-top: 7px;
    font-style: normal;
  }

  .remove {
    animation: slideRight 300ms linear;
    align-self: start;
  }

  @keyframes slideRight {
    0% {
    }
    100% {
      margin-left: 270px;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.mt_02 {
  margin-top: 2px;
}

.modal_footer {
  background: var(--light-grey);
  padding: 1.25rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.aanvullende_entry {
  min-height: 40px;
}

.aanvullende_card {
  min-width: 450px !important;
}

.platform_errors {
  word-wrap: break-word;
  min-width: 400px !important;
  max-width: 450px !important;
  padding: 2px !important;
}
