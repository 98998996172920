.input_control {
    height: 100%;
    width:30px;
    display: block;
    font-family: var(--source-sans-pro);
    font-size: var(--font-input);
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-color);
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid var(--border-color-focus);
    position: relative;
    bottom: -1px;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
    &:focus {
      color: var(--text-color);
      background-color: transparent;
      outline: 0;
    }
    &:disabled,
    &:read-only {
      border: none;
      color: var(--disabled-text-color);
    }
  }